<template>
    <v-container class="d-flex justify-center">
        <v-expansion-panels accordion>
            <v-expansion-panel>
                <v-expansion-panel-header class="text-overline"
                    >Spotlight</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                    <Spotlight />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script>
export default {
    components: {
        Spotlight: () => import('@/components/admin/Spotlight'),
    },
    data() {
        return {
            schema: {
                type: 'object',
                properties: {
                    title: {
                        type: 'string',
                        title: 'Heading',
                    },
                    body: {
                        type: 'string',
                        title: 'Message',
                        'x-display': 'textarea',
                    },
                    type: {
                        value: 'message',
                    },
                },
            },
            model: {},
            valid: true,
        }
    },
}
</script>

<style scoped></style>
